export class Cart
{
    cart = document.querySelector('[data-s~="Cart"]')
    buttonsHide = document.querySelectorAll('[data-s~="Cart:hide"]')
    status = false

    show()
    {
        if (!this.status) {
            document.body.style.overflow = "hidden"
            document.body.style.paddingRight = "17px"

            this.cart.style.display = "flex"

            setTimeout(() => {
                this.cart.style.transform = "translateX(0)"
            }, 100)

            setTimeout(() => {
                this.status = true
            }, 250)
        }
    }

    hide()
    {
        if (this.status) {
            this.cart.style.transform = "translateX(500px)"

            setTimeout(() => {
                this.status = false
                this.cart.style.display = null
                document.body.style.overflow = null
                document.body.style.paddingRight = null
            }, 500)
        }
    }

    watch()
    {
        addEventListener("cart", (e) => {
            if (
                e.detail.action == "show" &&
                e.detail.type == "update"
            ) {
                this.show()
            }
        })
        this.buttonsHide.forEach(e => {
            e.addEventListener("click", () => {
                this.hide()
            })
        })
    }
}
