import { Carousel } from "./../Service/Carousel.js"
import { Preloader } from "./../Service/Preloader.js"
import { Modal } from "./../Service/Modal.js"
import { ScrollToTop } from "./../Service/ScrollToTop.js"
import { SearchModal } from "./../Service/SearchModal.js"
import { Cookies } from "./../Service/Cookies.js"
import { Icons } from "../Service/Icons.js"
import { Switch } from "./../Service/Switch.js"
import { CartSave } from "./../Service/CartSave.js"
import { CartRead } from "./../Service/CartRead.js"
import { Cart } from "./../Service/Cart.js"

export class Application
{
    constructor(store)
    {
        this.store = store

        this.store.service.Carousel = new Carousel()
        this.store.service.Preloader = new Preloader()
        this.store.service.Modal = new Modal(store)
        this.store.service.ScrollToTop = new ScrollToTop()
        this.store.service.SearchModal = new SearchModal(store)
        this.store.service.Cookies = new Cookies()
        this.store.service.Icons = new Icons()
        this.store.service.Switch = new Switch()
        this.store.service.CartSave = new CartSave()
         this.store.service.CartRead = new CartRead()
        this.store.service.Cart = new Cart()
    }

    run()
    {
        this.store.service.Carousel.run()
        this.store.service.Carousel.watch()
        this.store.service.Preloader.watch()
        this.store.service.Modal.watch()
        this.store.service.ScrollToTop.watch()
        this.store.service.SearchModal.watch()
        this.store.service.Cookies.run()
        this.store.service.Cookies.watch()
        this.store.service.Icons.run()
        this.store.service.Icons.watch()
        this.store.service.Switch.watch()
        this.store.service.CartSave.watch()
        this.store.service.CartRead.watch()
        this.store.service.Cart.watch()
    }
}
