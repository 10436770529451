export class ScrollToTop
{
	button = document.querySelector('[data-s~="ScrollToTop"]')

    show()
    {
        this.button.style.display = "flex"

        setTimeout(() => {
            this.button.style.opacity = "1"
        }, 250)
    }

    hide()
    {
        this.button.style.opacity = null

        setTimeout(() => {
            this.button.style.display = null
        }, 300)
    }

    check()
    {
        if (window.innerHeight + window.pageYOffset > document.body.offsetHeight - 1000) {
            this.show()
        } else if (window.innerHeight + window.pageYOffset > document.body.offsetHeight - 1500) {
            this.hide()
        }
    }

    scroll()
    {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

	watch()
	{
        document.addEventListener("load", () => {
            this.check()
        })
        document.addEventListener("scroll", () => {
            this.check()
        })
        document.addEventListener("resize", () => {
            this.check()
        })
        this.button && this.button.addEventListener("click", () => {
            this.scroll()
        })
	}
}
