export class CartSave
{
    constructor()
    {
        this.cartForm = document.querySelector('#dodaj-form');
        this.operationField = this.cartForm && this.cartForm.querySelector(':scope [name=operacja]');
        this.idProdField = this.cartForm && this.cartForm.querySelector(':scope [name=id_prod]');
        this.idPriceField = this.cartForm && this.cartForm.querySelector(':scope [name=id_ceny]');
        this.amountField = this.cartForm && this.cartForm.querySelector(':scope [name=ilosc]');
        this.paramsIdsFields = this.cartForm && this.cartForm.querySelectorAll(':scope .parametr-lista');
    }

    prepareData(dataToCode)
    {
        const dataPart = [];
        for (let key in dataToCode) {
            dataPart.push(encodeURIComponent(key) + "=" + encodeURIComponent(dataToCode[key]));
        }
        return dataPart.join("&").replace(/%20/g, "+");
    }

    async addToCart()
    {
        let paramsIds = [];
        for (let field of this.paramsIdsFields) {
            if (field.type === 'radio') {
                if (field.checked === true) {
                    paramsIds.push(field.value);
                }
            }
            else {
                paramsIds.push(field.value);
            }
        }

        const data = {
            rand: shoppingCartRandom,
            token: shoppingCartToken,
            operacja: this.operationField.value,
            id_prod: this.idProdField.value,
            id_ceny: this.idPriceField.value,
            ilosc: this.amountField.value,
            paramIds: paramsIds
        };
        const dataToSend = this.prepareData(data);

        const response = await fetch('/sklep/obsluga_koszyka_ajax.php', {
            method: 'POST',
//            mode: 'cors',
            cache: 'no-cache',
//            credentials: 'same-origin',
            headers: {
//                'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded'
            },
//            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: dataToSend
        });

        return response.json();
    }

    watch()
    {
        this.cartForm && this.cartForm.addEventListener('submit', (e) => {
            e.preventDefault();
            this.addToCart().then(data => {
                if (data.result !== 'token_error') {
                    if (data.result === true) {
                        const event = new Event("shoppingCartUpdated");
                        document.dispatchEvent(event);

                        dispatchEvent(new CustomEvent("cart", { detail: {
                            action: "show",
                            type: "update",
                        }}))
                    } else {
                        const event = new Event("shoppingCartError");
                        document.dispatchEvent(event);

                        dispatchEvent(new CustomEvent("cart", { detail: {
                            action: "show",
                            type: "error",
                        }}))
                    }
                } else {
                    alert('Błąd tokenu! Proszę odświeżyć stronę i spróbować ponownie.');
                }
            });
        });
    }
}
