export class CartRead
{
    constructor()
    {
//        this.basketButton = document.querySelector('[data-i~="basket"]');
        this.shoppingCartAmount = document.querySelector('[data-s~="ShoppingBasketAmount_1__1"]');
        this.shoppingCartAmount2 = document.querySelector('[data-s~="ShoppingBasketAmount_2__1"]');
        this.shoppingCartTotal = document.querySelector('[data-s~="ShoppingBasketTotal_1__1"]');
        this.shoppingCartTotal2 = document.querySelector('[data-s~="ShoppingBasketTotal_2__1"]');
        this.shoppingBasketItems = document.querySelector('[data-s~="ShoppingBasketItems_1__1"]');
        this.shoppingBasketItemTemplate = document.querySelector('[data-s~="ShoppingBasketItemTemplate_1__1"]');
        this.shoppingBasketButton = document.querySelectorAll('[data-s~="ShoppingBasketButton_1__1"]');
        this.SBI_Link = this.shoppingBasketItemTemplate.querySelector(':scope [data-s~="SBI_Link_1__1"]');
        this.SBI_Img = this.shoppingBasketItemTemplate.querySelector(':scope [data-s~="SBI_Img_1__1"]');
        this.SBI_Product = this.shoppingBasketItemTemplate.querySelector(':scope [data-s~="SBI_Product_1__1"]');
        this.SBI_Parameters = this.shoppingBasketItemTemplate.querySelector(':scope [data-s~="SBI_Parameters_1__1"]');
        this.SBI_Amount = this.shoppingBasketItemTemplate.querySelector(':scope [data-s~="SBI_Amount_1__1"]');
        this.SBI_Price = this.shoppingBasketItemTemplate.querySelector(':scope [data-s~="SBI_Price_1__1"]');
        this.shoppingBasketError = document.querySelector('[data-s~="ShoppingBasketError_1__1"]');
        this.TokenErrorText = 'Błąd tokenu! Proszę odświeżyć stronę i spróbować ponownie.';
    }

    prepareData(dataToCode)
    {
        const dataPart = [];
        for (let key in dataToCode) {
            dataPart.push(encodeURIComponent(key) + "=" + encodeURIComponent(dataToCode[key]));
        }
        return dataPart.join("&").replace(/%20/g, "+");
    }

    async getShoppingCart()
    {
        const data = {
            rand: shoppingCartRandom,
            token: shoppingCartToken
        };
        const dataToSend = this.prepareData(data);

        const response = await fetch('/sklep/koszyk_ajax.php', {
            method: 'POST',
//            mode: 'cors',
            cache: 'no-cache',
//            credentials: 'same-origin',
            headers: {
//                'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded'
            },
//            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: dataToSend
        });

        return response.json();
    }

    modifyShoppingCartIcon(amount)
    {
        this.shoppingCartAmount.textContent = amount;
    }

    modifyModalTotalCartIcon(total)
    {
        this.shoppingCartTotal2.textContent = total;
    }

    modifyModalAmount(amount)
    {
        this.shoppingCartAmount2.textContent = amount;
    }

    modifyModalTotal(total)
    {
        this.shoppingCartTotal.textContent = total;
    }

    modifyModalProducts(products)
    {
        this.shoppingBasketItems.innerHTML = '';

        for (let item of products) {
            this.SBI_Link.href = item.link;
            if (item.zdjecie) {
                this.SBI_Img.src = item.zdjecie;
            }
            this.SBI_Product.textContent = item.nazwa;
            this.SBI_Parameters.insertAdjacentHTML('afterbegin', item.parametry);
            this.SBI_Amount.textContent = item.ilosc;
            this.SBI_Price.textContent = item.cena;

            let newItem = this.shoppingBasketItemTemplate.cloneNode(true);
            newItem.removeAttribute('data-s');
//            newItem.setAttribute('data-d', 'wh=100%');
            newItem.removeAttribute('data-aa');
            this.shoppingBasketItems.appendChild(newItem);

            this.SBI_Link.href = '';
            this.SBI_Img.src = '';
            this.SBI_Product.textContent = '';
            this.SBI_Parameters.innerHTML = '';
            this.SBI_Amount.textContent = '';
            this.SBI_Price.textContent = '';
        }
    }

    openCartPreview()
    {
//        this.basketButton.click();
        dispatchEvent(new CustomEvent("cart", { detail: {
            action: "show",
            type: "update",
        }}));
    }

    showErrorMessage()
    {
        this.shoppingBasketError.setAttribute('data-d', 'wh=100% mn-tp=20px');
    }

    removeErrorMessage()
    {
        this.shoppingBasketError.setAttribute('data-d', 'wh=100% mn-tp=20px dy=ne');
    }

    watch()
    {
        document.addEventListener('shoppingCartUpdated', (e) => {
            this.getShoppingCart().then(data => {
                if (data.result !== 'token_error') {
                    this.removeErrorMessage();
                    this.modifyShoppingCartIcon(data['ilosc']);
                    this.modifyModalAmount(data['ilosc']);
                    this.modifyModalTotal(data['wartosc']);
                    this.modifyModalTotalCartIcon(data['wartosc_raw']);
                    this.modifyModalProducts(data['produkty']);
                    this.openCartPreview();
                }
                else {
                    alert(this.TokenErrorText);
                }
            });
        });

        document.addEventListener('shoppingCartError', (e) => {
            this.getShoppingCart().then(data => {
                if (data.result !== 'token_error') {
                    this.showErrorMessage();
                    this.openCartPreview();
                }
                else {
                    alert(this.TokenErrorText);
                }
            });
        });

        document.addEventListener('DOMContentLoaded', () => {
            this.getShoppingCart().then(data => {
                if (data.result !== 'token_error') {
                    this.modifyModalProducts(data['produkty']);
                }
                else {
                    alert(this.TokenErrorText);
                }
            });

            this.shoppingBasketButton.forEach((button) => {
                button.addEventListener('click', (e) => {
                    e.preventDefault();
                    this.openCartPreview();
                });
            });
        });
    }
}