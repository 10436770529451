import { Cookie } from "./../Application/Cookie.js"

export class Cookies
{
    cookies = document.querySelector('[data-s~="Cookies"]')
    buttonHide = document.querySelector('[data-s~="Cookies:hide"]')
    Cookie = new Cookie()

	constructor()
	{
		this.config = {
			name: "Cookies",
			value: "false",
			domain: "",
			path: "/",
			expires: 1,
			secure: ""
		}
	}

	show()
	{
		this.cookies.style.display = "flex"
	}

	hide()
	{
		this.cookies.style.display = null
		this.Cookie.create(this.config)
	}

	run()
	{
		let cookie = this.Cookie.read("Cookies")

		if (cookie != "false") {
			this.show()
		}
	}

	watch()
	{
        this.buttonHide && this.buttonHide.addEventListener("click", () => {
            this.hide()
        })
	}
}
