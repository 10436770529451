export class SearchModal
{
    field = document.querySelector('[data-s~="SearchModal:field"]')

    constructor(store)
    {
        this.store = store
    }

    watch()
    {
        addEventListener("modal", (e) => {
            if (
                e.detail.action == "show" &&
                e.detail.target == "search"
            ) {
                this.field.focus()
            }
        })
    }
}
